import React, { useContext, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { Helmet } from "rnd-helmet";
import ListItem from "@Components/ListItem";
import { useDataLayer } from "dataLayerHelper";
import SectionHeader from "@Components/SectionHeader";
import Loading from "@Components/Loading";
import Modal from "@Components/Modal";
import Button from "@FormElements/Button";
import SubMenu from "@Components/SubMenu";
import Form from "form";
import FormContext from "formContext";
import axios from "axios";
import Logger from "@ffw/logger-lib";
import atomeFieldMapping from "@Utils/atomeFieldsHelper";

function JobPreferences({ intl, staticContext, match }) {
  const {
    formData,
    setFormData,
    handleErrors,
    currentStep,
    getFormSchema,
    showToast,
    setToastMessage,
    setErrors,
    additionalData,
    setAdditionalData,
    formName,
    setFormName,
    modalOpen,
    timesRendered,
    setModalOpen,
    triggerRerender,
    handleChange,
    editedItemKey,
    setEditedItemKey
  } = useContext(FormContext);
  const { user, specialisms, masterLists } = additionalData;
  // Handle CN renamed keys and fields.
  if (process.env.REACT_APP_COUNTRY === 'cn' && user) {
    user.WillingToRelocate = user.willing_to_relocate;
    user.vehicle_available = user.has_own_transport;
    user.WillingToTravelDistance = user.distance;
  }

  const { locale } = intl;
  const jobIndustryFormName = "job-industry";
  const workFormName = "work";
  const locationFormName = "location";
  const commuteFormName = "commute";
  const contactPreferencesFormName = "contact-preferences";
  const [loading, setLoading] = useState(false);
  const useIndustry = ["au", "hk", "my", "nz", "sg", "cn"].includes(process.env.REACT_APP_COUNTRY);
  let showBranchSubSpec = process.env.REACT_APP_COUNTRY === "uk" && user && user.branch_sub_specialism;

  // Generate dataLayer for the page.
  useDataLayer(locale, staticContext);

  useEffect(() => {
    if (window.__ROUTE_DATA__) {
      delete window.__ROUTE_DATA__;
    }
    else {
      axios.get(`${process.env.REACT_APP_API_PREFIX}/user-data?currentLanguage=${locale}`)
        .then(response => {
          if (response.data.redirect === true && response.data.location) {
            window.location.href = response.data.location;
          }
          else {
            showBranchSubSpec = process.env.REACT_APP_COUNTRY === "uk" && response.data.user.branch_sub_specialism;
            setAdditionalData(prevState => ({
              ...prevState,
              ...response.data
            }));
          }
        });
    }
  }, [formData]);

  if (!user) {
    return <Loading/>
  }

  const modalFooter = () => {
    const modalButtonSettings = {
      medium: true,
      filled: true,
      fullWidth: true,
      text: getFormSchema(formName)[currentStep].modalButton,
      loading
    }

    return <Button onClick={handleSubmit} settings={modalButtonSettings} />
  }

  const renderModal = () => {
    if (!modalOpen || !formName) {
      return '';
    }

    return (
      <Modal
        title={intl.formatMessage({id: getFormSchema(formName)[currentStep].modalTitle})}
        onClose={onCloseModal}
        footer={modalFooter()}
        footerDivider={true}
      >
        <Form name={formName} handleChange={handleChange} state={formData[currentStep]} handleSubmit={handleSubmit}/>
      </Modal>
    );
  };

  const onCloseModal = () => {
    setFormData([]);
    setFormName('');
    setEditedItemKey('');
    setErrors();
    setModalOpen(false);
  };

  const handleSubmit = event => {
    event.preventDefault();

    setLoading(true);
    let submitApiPath;
    const data = formData[currentStep];

    switch (formName) {
      case jobIndustryFormName:
        submitApiPath = "/user/job-industry/";
        break;
      case workFormName:
        submitApiPath = "/user/work/";
        break;
      case locationFormName:
        submitApiPath = "/user/location/";
        break;
      case commuteFormName:
        submitApiPath = "/user/commute/";
        break;
      case contactPreferencesFormName:
        submitApiPath = "/user/contact-preferences/";
        break;
    }

    if (!submitApiPath) {
      Logger.info(`Unable to update data. Missing API path for ${formName} form`, `job-preferences/${process.env.REACT_APP_COUNTRY}`);
      return null;
    }

    // Set specialism id and subspecialism id for all opCos except APAC, uk, cn, lu.
    if (formName === jobIndustryFormName && !useIndustry && process.env.REACT_APP_COUNTRY !== "uk" && process.env.REACT_APP_COUNTRY !== "lu") {
      if (process.env.REACT_APP_JOB_PREF_FIELD_SPECIALISM === "true") {
        const currentSpec = data['specialism'] ? specialisms.find(spec => spec.name === data['specialism']) : null;
        data['specialism_id'] = currentSpec ? currentSpec.id : '';

        if (process.env.REACT_APP_JOB_PREF_FIELD_SUBSPECIALISM === "true") {
          const currentSubSpec = currentSpec ? currentSpec.nested.find(sub => sub.name === data['sub_specialism']) : null;
          data['sub_specialism_id'] = currentSubSpec ? currentSubSpec.id : '';
        }
      }
    }

    axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_PREFIX}${submitApiPath}`,
      data: data
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          setModalOpen(false);
          setFormData([]);
          setEditedItemKey('');
          setErrors();
          setLoading(false)
          setTimeout(() => {
            const message = intl.formatMessage({id: 'Profile.AccountUpdated.Toast.SuccessMessage'});
            setToastMessage(message);
            showToast();
          }, 500);
          triggerRerender();
        }
      })
      .catch(error => {
        setLoading(false)
        handleErrors(error.response.data);
      });
  };

  const formDataHandler = (dataForForm, formName) => {
    setFormData([dataForForm]);
    setFormName(formName);
    setModalOpen(true);
  }

  const handleEditJobIndustry = (event) => {
    event.preventDefault();
    let dataForForm = {};
    if (process.env.REACT_APP_JOB_PREF_FIELD_BUSINESS_SECTOR === "true") {
      dataForForm.businessSector = user.businessSectorId;
    }

    if (process.env.REACT_APP_JOB_PREF_FIELD_SPECIALISM === "true") {
      if (useIndustry) {
        dataForForm.industry = user.industry;
        dataForForm.industry_sector_codes = user.industry_sector_codes;
      }
      else {
        dataForForm.specialism = user.specialism;
      }
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_SPECIALISM_1 === "true") {
      dataForForm.specialism1 = user.specialism1;
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_SPECIALISM_2 === "true") {
      dataForForm.specialism2 = user.specialism2;
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_SPECIALISM_3 === "true") {
      dataForForm.specialism3 = user.specialism3;
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_SUBSPECIALISM === "true") {
      dataForForm.sub_specialism = process.env.REACT_APP_COUNTRY === "ch" ? user.sub_specialism_key : user.sub_specialism;
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_EXPERIENCES === "true") {
      dataForForm.experiences = user.experiences;
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_EXPERIENCE_LEVEL === "true") {
      dataForForm.experience_level = user.experience_level;
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_JOB_TITLE === "true") {
      if (process.env.REACT_APP_COUNTRY === 'fr') {
        if (user && user.atomeData && user.atomeData.Qualifications) {
          dataForForm.QualificationTitle = user.atomeData.Qualifications.length && user.atomeData.Qualifications[0].hasOwnProperty('Value') ? user.atomeData.Qualifications[0].Value.trim() : '';
          dataForForm.QualificationIDs = user.atomeData.Qualifications.length && user.atomeData.Qualifications[0].hasOwnProperty('ID') ? user.atomeData.Qualifications[0].ID.trim() : '';
        }
      } else {
        dataForForm.job_title = user.job_title;
      }
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_BRANCH === "true") {
      dataForForm.branch = user.branch;
    }
    formDataHandler(dataForForm, jobIndustryFormName);
  }

  const handleEditWork = (event) => {
    event.preventDefault();
    let dataForForm = {};
    if (process.env.REACT_APP_JOB_PREF_FIELD_CONTRACT_TYPE === "true") {
      switch (process.env.REACT_APP_COUNTRY) {
        case "de":
        case "ro":
        case "at":
        case "it":
        case "ch":
        case "hu":
          dataForForm.job_type_permanent = user.job_type_permanent;
          dataForForm.job_type_temporary = user.job_type_temporary;
          break;

        case "fr":
          if (user.atomeData.hasOwnProperty('Contrats') && user.atomeData?.Contrats?.length) {
            user.atomeData.Contrats.forEach((value) => {
              dataForForm.ContractTypeIDs = {
                ...dataForForm.ContractTypeIDs,
                [value.ID]: true
              };
            });
          }
          break;

        default:
          dataForForm.job_type = user.job_type;
          break;

      }
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_HOURS === "true") {
      dataForForm.working_time_full = user.working_time_full;
      dataForForm.working_time_part = user.working_time_part;
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_SALARY === "true") {
      dataForForm.salary_rate_amount = user.salary_rate_amount;
      dataForForm.salary_rate_type = user.salary_rate_type;

      if (process.env.REACT_APP_COUNTRY === "cn") {
        dataForForm.salary = user.salary;
      }
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_AVAILABLE === "true") {
      dataForForm.available = user.available;
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_AVAILABILITY === "true") {
      dataForForm.availability = user.availability;
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_NOTICE_PERIOD === "true") {
      dataForForm.notice_period = user.notice_period;
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_PERM_SALARY === "true") {
      dataForForm.perm_salary = user.PermSalary && user.PermSalary.SalaryRange ? user.PermSalary.SalaryRange : '';
      dataForForm.perm_salary_type = user.PermSalary && user.PermSalary.SalarySchemeType ? user.PermSalary.SalarySchemeType : '';
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_TEMP_SALARY === "true") {
      dataForForm.temp_salary = user.TempSalary && user.TempSalary.SalaryRange ? user.TempSalary.SalaryRange : '';
      dataForForm.temp_salary_type = user.TempSalary && user.TempSalary.SalarySchemeType ? user.TempSalary.SalarySchemeType : '';
    }
    formDataHandler(dataForForm, workFormName);
  };

  const handleEditLocation = (event) => {
    event.preventDefault();
    let dataForForm = {};
    if (process.env.REACT_APP_JOB_PREF_FIELD_LOCATION) {
      dataForForm.workplace = user.workplace;
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_REGION) {
      dataForForm.region = user.region;
    }
    formDataHandler(dataForForm, locationFormName);
  };

  const handleEditCommute = (event) => {
    event.preventDefault();
    let dataForForm = {};
    if (process.env.REACT_APP_JOB_PREF_FIELD_MAXIMUM_COMMUTE === "true") {
        dataForForm.distance = user.WillingToTravelDistance;
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_CAR_OWNER === "true") {
      // CN uses different key for car owner.
      if (process.env.REACT_APP_COUNTRY === 'cn') {
        dataForForm.has_own_transport = user.has_own_transport;
      } else {
        dataForForm.vehicle_available = user.vehicle_available;
      }
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_DRIVING_LICENSE === "true") {
      dataForForm.driving_licence = user.driving_licence;
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_RELOCATE === "true") {
        dataForForm.willing_to_relocate = user.WillingToRelocate;
    }
    formDataHandler(dataForForm, commuteFormName);
  };

  const handleEditContactPreferences = (event) => {
    event.preventDefault();
    let dataForForm = {};
    if (process.env.REACT_APP_JOB_PREF_FIELD_PNONE_AVAILABILITY === "true") {
      dataForForm.phone_availability = user.phone_availability;
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_BRANCH_SPECIALISM === "true") {
      dataForForm.branch_specialism = user.branch_specialism;
    }
    if (process.env.REACT_APP_JOB_PREF_FIELD_BRANCH_LOCATION === "true") {
      dataForForm.local_branch = user.local_branch;
      dataForForm.branch_sub_specialism = user.branch_sub_specialism;
    }

    formDataHandler(dataForForm, contactPreferencesFormName);
  };

  const getUserSalaryMessage = () => {
    if (user.salary_rate_amount !== undefined && user.salary_rate_type !== undefined) {
      if (process.env.REACT_APP_COUNTRY === "ch" && user.salary_rate_type === "-") {
        return "";
      }
      return intl.formatMessage({id: 'JobPreferences.Work.ExpectedSalary.Message'}, {
        'currency': process.env.REACT_APP_CURRENCY_SYMBOL,
        'number': user.salary_rate_amount,
        'rate': user.salary_rate_type ? intl.formatMessage({id: `SelectField.SalaryRateType.${user.salary_rate_type}`}) : ""
      });
    }
    else if (process.env.REACT_APP_COUNTRY === "cn") {
      return user.salary;
    }
    else {
      return '';
    }
  };

  const buildCheckboxesMessage = (data, items, checkedValue = "yes") => {
    let messageParts = [];
    const itemsKeys = Object.keys(items);
    itemsKeys.forEach((fieldName, index) => {
      const translationId = items[fieldName];
      if (data && data.hasOwnProperty(fieldName) && data[fieldName] === checkedValue) {
        messageParts.push(intl.formatMessage({id: translationId}));
      }
    })

    const selectedValues = messageParts.map(value => {
      return <span className="badge badge--tertiary badge--l">{value}</span>;
    });
    return selectedValues;
  };

  const getWorkingTimeMessage = () => {
    return buildCheckboxesMessage(user, {
      working_time_full: "JobPreferences.Work.WorkingTime.FullTime",
      working_time_part: "JobPreferences.Work.WorkingTime.PartTime",
    });
  }

  const getJobTypeMessage = () => {
    if (process.env.REACT_APP_COUNTRY === "ca") {
      switch (user.job_type) {
        case 'permanent':
          return intl.formatMessage({id: "JobPreferences.Work.JobType.Permanent"});
        case 'temporary':
          return intl.formatMessage({id: "JobPreferences.Work.JobType.Temporary"});
        default:
          return '';
      }
    }

    return buildCheckboxesMessage(user, {
      job_type_permanent: "JobPreferences.Work.JobType.Permanent",
      job_type_temporary: "JobPreferences.Work.JobType.Temporary",
    });
  }

  const displayYesNoCheckboxValue = (value, fieldName) => {
    if (process.env.REACT_APP_COUNTRY === "cn") {
      if (fieldName === 'vehicle_available') {
        return value === 'true' ? intl.formatMessage({id: 'PersonalInformation.VehicleAvailable.Yes'}) : intl.formatMessage({id: 'PersonalInformation.VehicleAvailable.No'});
      }
      if (fieldName === 'relocate') {
        return value === 'true' ? intl.formatMessage({id: 'PersonalInformation.Relocate.Yes'}) : intl.formatMessage({id: 'PersonalInformation.Relocate.No'});
      }
    }
    if ((value !== undefined && value.length > 0) || value === true) {
      if (process.env.REACT_APP_COUNTRY === "gr") {
        return intl.formatMessage({id: `SelectField.DrivingLicense.${user.driving_licence}`});
      }
      return (value === 'yes' || value === true) ? intl.formatMessage({id: 'Utils.Yes'}) : intl.formatMessage({id: 'Utils.No'});
    }
    else {
      return '';
    }
  }

  const renderBusinessSector = () => {
    return user.businessSector;
  }

  const renderSpecialism1 = () => {
    return user.specialism1 && intl.formatMessage({id: `SelectField.Specialism.${user.specialism1}`});
  }

  const renderSpecialism2= () => {
    return user.specialism2 && intl.formatMessage({id: `SelectField.Specialism.${user.specialism2}`});
  }

  const renderSpecialism3 = () => {
    return user.specialism3 && intl.formatMessage({id: `SelectField.Specialism.${user.specialism3}`});
  }

  const renderSpecialism = () => {
    if (useIndustry) {
      let industries = user.industry ? user.industry : [];
      industries = industries.map((industry, index) =>
        <span key={index} className="badge badge--tertiary badge--l">{industry}</span>
      );

      return industries ? industries : '-';
    }

    switch (process.env.REACT_APP_COUNTRY) {
      case "pt":
        return user.specialism_name ? user.specialism_name : '-';
      case "lu":
      case "hu":
        return user.rendered_specialism ? user.rendered_specialism : '-';
      case "ro":
      case "ch":
        return user.specialism ? intl.formatMessage({id: `SelectField.Specialism.${user.specialism}`}) : '-';
      default:
        return user.specialism ? user.specialism : '-';
    }
  }

  const renderExperiences = () => {
    if (!user.experiences || !user.experiences_list) {
      return '';
    }

    const renderedExperiences = [];
    user.experiences_list.forEach((experience, index) => {
        renderedExperiences.push(
          <span key={index} className="badge badge--tertiary badge--l">{experience}</span>
        );
    });

    return renderedExperiences;
  }

  /**
   * FR specific method for Contract type value field.
   * @returns {string|null}
   */
  const contractValues = () => {
    let contracts = [];
    if (!user.atomeData.Contrats) {
      return null;
    }

    // FR Specific mapping
    const {
      contractTypeMapping,
    } = atomeFieldMapping(intl);

    const itemsKeys = Object.keys(user.atomeData.Contrats);
    itemsKeys.forEach((i) => {
      if (user.atomeData.Contrats[i] && user.atomeData.Contrats[i].ID) {
        contracts.push(contractTypeMapping[user.atomeData.Contrats[i].ID]);
      }
    })

    return contracts.map((contract, index) =>
      <span key={index} className="badge badge--tertiary badge--l">{contract}</span>
    );
  };

  const renderContractType = () => {
    switch (process.env.REACT_APP_COUNTRY) {
      case "fr":
        return contractValues();
      case "de":
      case "ro":
      case "at":
      case "it":
      case "ch":
      case "hu":
      case "ca":
        return getJobTypeMessage().length !== 0 ? getJobTypeMessage() : '-';
      case "uk":
      case "cn":
        return user.job_type ? user.job_type : '-';
      default:
        return user.job_type_name ? user.job_type_name : '-';
    }
  }

  const renderRegion = () => {
    if (process.env.REACT_APP_COUNTRY === "pt") {
      return user.region_name ? user.region_name : '-'
    }
    else {
      return user.region ? user.region : '-'
    }
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'Menu.JobPreferences'})} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
        <header className="header bg-variant-white header--s header--welcome"/>
      </Helmet>
      <SubMenu />

      <div className="block__wrapper wrapper pt-l l:pt-xl">
        <SectionHeader title={intl.formatMessage({id: 'JobPreferences.Title'})}
                       description={intl.formatMessage({id: 'JobPreferences.Description'})} />
        <div className="block__content block__content--align-right block__content--s">
          {
            process.env.REACT_APP_SECTION_JOB_INDUSTRY === "true" &&
            <ListItem
              title={intl.formatMessage({id: 'JobPreferences.JobAndIndustry.Title'})}
              handleEdit={handleEditJobIndustry}
              label={intl.formatMessage({id: 'JobPreferences.JobAndIndustry.Title'})}
              divider="true"
            >
              { process.env.REACT_APP_JOB_PREF_FIELD_BUSINESS_SECTOR === "true" &&
              <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.JobAndIndustry.BusinessSector.Label'})}</div>
                <div className="my-environment-item__list-content mt-xxs l:mt-none">
                  {renderBusinessSector()}
                </div>
              </div>
              }
              { process.env.REACT_APP_JOB_PREF_FIELD_SPECIALISM === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.JobAndIndustry.Specialism.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">
                    <div className="element-group">
                      {renderSpecialism()}
                    </div>
                  </div>
                </div>
              }
              { process.env.REACT_APP_JOB_PREF_FIELD_SPECIALISM_1 === "true" &&
              <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.JobAndIndustry.Specialism1.Label'})}</div>
                <div className="my-environment-item__list-content mt-xxs l:mt-none">
                  {renderSpecialism1()}
                </div>
              </div>
              }
              { process.env.REACT_APP_JOB_PREF_FIELD_SPECIALISM_2 === "true" &&
              <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.JobAndIndustry.Specialism2.Label'})}</div>
                <div className="my-environment-item__list-content mt-xxs l:mt-none">
                  {renderSpecialism2()}
                </div>
              </div>
              }
              { process.env.REACT_APP_JOB_PREF_FIELD_SPECIALISM_3 === "true" &&
              <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.JobAndIndustry.Specialism3.Label'})}</div>
                <div className="my-environment-item__list-content mt-xxs l:mt-none">
                  {renderSpecialism3()}
                </div>
              </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_SUBSPECIALISM === "true" &&
                <div className={`my-environment-item__list my-environment-item__list-details mt-s l:mt-xs ${user.sub_specialism ? ' large-list--filled': ''}`}>
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.JobAndIndustry.SubSpecialism.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">
                    {user.rendered_sub_specialism ? user.rendered_sub_specialism : user.sub_specialism ? user.sub_specialism : '-'}
                  </div>
                </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_EXPERIENCES === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.JobAndIndustry.Experience.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">
                    <div className="element-group">
                      {renderExperiences()}
                    </div>
                  </div>
                </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_EXPERIENCE_LEVEL === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.JobAndIndustry.ExperienceLevel.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">
                    {user.experience_level ? intl.formatMessage({id: `ExperienceLevel.${user.experience_level}`}): '-'}
                  </div>
                </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_BRANCH === "true" &&
                <div className={`my-environment-item__list my-environment-item__list-details mt-s l:mt-xs ${user.branch ? ' large-list--filled': ''}`}>
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.JobAndIndustry.SubSpecialism.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">
                    {user.branch ? user.branch : '-'}
                  </div>
                </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_JOB_TITLE === "true" && process.env.REACT_APP_COUNTRY !== "fr" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.JobAndIndustry.JobTitle.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.job_title ? user.job_title : '-'}</div>
                </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_JOB_TITLE === "true" && process.env.REACT_APP_COUNTRY === "fr" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.JobAndIndustry.JobTitle.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.QualificationTitle ? user.QualificationTitle : '-'}</div>
                </div>
              }
            </ListItem>
          }
          {
            process.env.REACT_APP_SECTION_WORK === "true" &&
            <ListItem
              title={intl.formatMessage({id: 'JobPreferences.Work.Title'})}
              handleEdit={handleEditWork}
              label={intl.formatMessage({id: 'JobPreferences.Work.Title'})}
              divider="true"
            >
              {
                process.env.REACT_APP_JOB_PREF_FIELD_CONTRACT_TYPE === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.Work.JobType.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">
                    <div className="element-group">
                      {renderContractType()}
                    </div>
                  </div>
                </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_HOURS === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div
                    className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.Work.WorkingTime.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">
                    <div className="element-group">
                      {getWorkingTimeMessage().length !== 0 ? getWorkingTimeMessage() : '-'}
                    </div>
                  </div>
                </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_SALARY === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.Work.ExpectedSalaryAmount.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{getUserSalaryMessage() ? getUserSalaryMessage() : '-'}</div>
                </div>
              }
              {
                  process.env.REACT_APP_JOB_PREF_FIELD_AVAILABILITY === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.Work.Availability.Label'})}</div>
                    <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.availability === 'available' ? intl.formatMessage({id: 'Availability.Available'}) : intl.formatMessage({id: 'Availability.Unavailable'})}</div>
                  </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_AVAILABLE_FROM === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.Work.AvailableFrom.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.available_from ? user.available_from : '-'}</div>
                </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_NOTICE_PERIOD === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.Work.NoticePeriod.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.notice_period_render ? user.notice_period_render : '-'}</div>
                </div>
              }
            </ListItem>
          }
          {
            process.env.REACT_APP_JOB_PREF_SECTION_LOCATION === "true" &&
            <ListItem
              title={intl.formatMessage({id: 'JobPreferences.Location.Title'})}
              handleEdit={handleEditLocation}
              label={intl.formatMessage({id: 'JobPreferences.Location.Title'})}
              divider="true"
            >
              {
                process.env.REACT_APP_JOB_PREF_FIELD_REGION === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.Location.PreferredRegion.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">
                    {renderRegion()}
                  </div>
                </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_LOCATION === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.Location.PreferredLocation.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">
                    {user.workplace ? user.workplace : '-'}
                  </div>
                </div>
              }
            </ListItem>
          }
          {
            process.env.REACT_APP_SECTION_COMMUTE === "true" &&
            <ListItem
              title={intl.formatMessage({id: 'JobPreferences.Commute.Title'})}
              handleEdit={handleEditCommute}
              label={intl.formatMessage({id: 'JobPreferences.Commute.Title'})}
              divider="true"
            >
              {
                process.env.REACT_APP_JOB_PREF_FIELD_MAXIMUM_COMMUTE === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.Commute.MaximumCommute.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.WillingToTravelDistance ? user.WillingToTravelDistance : '-'}</div>
                </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_DRIVING_LICENSE === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.Commute.DrivingLicense.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{displayYesNoCheckboxValue(user.driving_licence) ? displayYesNoCheckboxValue(user.driving_licence) : '-'}</div>
                </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_CAR_OWNER === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.Commute.CarOwner.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{displayYesNoCheckboxValue(user.vehicle_available, 'vehicle_available') ? displayYesNoCheckboxValue(user.vehicle_available, 'vehicle_available') : '-'}</div>
                </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_RELOCATE === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.Commute.WillingToRelocate.Label'})}</div>
                    <div className="my-environment-item__list-content mt-xxs l:mt-none">{displayYesNoCheckboxValue(user.WillingToRelocate, 'relocate') ? displayYesNoCheckboxValue(user.WillingToRelocate, 'relocate') : '-'}</div>
                  </div>
              }
            </ListItem>
          }
          {
            process.env.REACT_APP_SECTION_CONTACT_PREFERENCES === "true" &&
            <ListItem
              title={intl.formatMessage({id: 'JobPreferences.ContactPreferences.Title'})}
              handleEdit={handleEditContactPreferences}
              label={intl.formatMessage({id: 'JobPreferences.ContactPreferences.Title'})}
              divider="true"
            >
              {
                process.env.REACT_APP_JOB_PREF_FIELD_BRANCH_SPECIALISM === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'Profile.Preferences.LocalBranch.BranchSpecialism.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.branch_specialism ? user.branch_specialism : '-'}</div>
                </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_BRANCH_LOCATION === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.ContactPreferences.LocalBranch.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.local_branch ? user.local_branch : '-'}</div>
                </div>
              }
              { showBranchSubSpec &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.JobAndIndustry.SubSpecialism.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.branch_sub_specialism}</div>
                </div>
              }
              {
                process.env.REACT_APP_JOB_PREF_FIELD_PNONE_AVAILABILITY === "true" &&
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'JobPreferences.ContactPreferences.PhoneAvailability.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.phone_availability && intl.formatMessage({id: `SelectField.PhoneAvailability.${user.phone_availability}`}) ? intl.formatMessage({id: `SelectField.PhoneAvailability.${user.phone_availability}`}) : '-'}</div>
                </div>
              }
            </ListItem>
          }
        </div>
      </div>
      {renderModal()}
    </>
  )
}

export default injectIntl(JobPreferences);
